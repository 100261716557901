<template>
  <div id="video">
    <div class="container text-center">
      <h2 class="pb-4">{{ "FestivalVideo.label" | staticTranslation }}</h2>
      <div class="col-lg-8 offset-lg-2">
        <div class="frameContainer box-shadow-3d">

<iframe loading="lazy" class="responsive-iframe" src="https://www.youtube.com/embed/yLml7p_KrQw?si=4eq9wmYm-gdosYUu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          <!-- <iframe loading="lazy" class="responsive-iframe" src="https://www.youtube.com/embed/p1yZAKM2YWI" title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "festivalVideo"
}
</script>

<style scoped>
h2 {
  padding-top: 1em;
  font-size: 2.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

#video {
  background-color: #ed764e;
  padding-bottom: 5em;
}

.frameContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.box-shadow-3d {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
